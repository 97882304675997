import React
//  {useState} 
 from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    // const [isMore, setMore] = useState(false)
    // const handleClick = () => {
    //     return setMore(!isMore)
    // }
        
    const reviewsChamb = [
        {
            name:"Beth W.",
            date:"6/25/2020",
            text:"He is competitive and does good work. If you are not satisfied, you can call him and he will come back and make things right. He is all about build long term relationships. Thats why I have been using him for years.",
            stars:5
        },
        
    ]

    return(
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>
                <a href="https://www.homeadvisor.com/rated.JFCGroupHome.37754990.html"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center">
                    <img
                    alt="logochamber"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fhomeadvisor.png?alt=media&token=5f7bbdeb-e5a8-470a-8a63-ee34d6e60661"
                   />
                </a> 
                

                {
                    // isMore?
                    // reviewsChamb.map((item, index) =>{
                    //     return(
                    //         <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                    //         <div className="flex justify-between p-5">
                    //             <div className="flex">
                    //             <img
                    //             src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                    //             alt="userphotodeatult"
                    //              className="w-[50px] shadow-md rounded-[50%]"/>
                    //             <div className="ml-3">
                    //                 <span className="font-medium">{item.name}</span>
                    //                 <Stars 
                    //                 score={item.stars}
                    //                 />
                    //             </div> 
                    //             </div>
                    //             <span className="">{item.date}</span>
    
                    //         </div>
                    //         <div className="p-5">
                    //             <p>{item.text}</p>
                    //         </div>

                    //     </section>
                    //     )
                    // })
                    // :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 rounded-lg shadow-lg border-gray-300 w-4/5 min-h-[100px] mx-auto mt-10">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                {/* <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button> */}
        </section> 
    );

}

export default ChamberReviews